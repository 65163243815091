var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card',[_c('b-form',{attrs:{"enctype":"multipart/forn-data"}},[_c('div',{staticClass:"d-flex mb-2 mt-2"},[_c('feather-icon',{attrs:{"icon":"ImageIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Image")])],1),_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":_vm.image,"size":"90px","rounded":""}})]},proxy:true}])},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputEl.click()}}},[_c('input',{ref:"refInputEl",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.uploadImage}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Add")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1)],1)]),_c('div',{staticClass:"d-flex mb-2 mt-2"},[_c('feather-icon',{attrs:{"icon":"BoxIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Product Detail")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Sku","label-for":"sku"}},[_c('validation-provider',{attrs:{"rules":"required","name":"sku"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sku","state":errors.length > 0 ? false : null},model:{value:(_vm.model.sku),callback:function ($$v) {_vm.$set(_vm.model, "sku", $$v)},expression:"model.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"price"}},[_c('validation-provider',{attrs:{"rules":"required|between:0.1,99999","name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Price","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.calculateOfferPrice()}},model:{value:(_vm.model.price),callback:function ($$v) {_vm.$set(_vm.model, "price", $$v)},expression:"model.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Type Commission","label-for":"user-type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Type Commission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeCommission,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-type"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Comission","label-for":"percentage_comission"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Comission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"percentage_comission","state":errors.length > 0 ? false : null},model:{value:(_vm.model.percentage_comission),callback:function ($$v) {_vm.$set(_vm.model, "percentage_comission", $$v)},expression:"model.percentage_comission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"user-status"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Discount","label-for":"user-type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.discountOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-type"},on:{"input":function($event){_vm.model.discount > 0
                    ? ((_vm.showValueDiscount = true), _vm.calculateOfferPrice())
                    : ((_vm.showValueDiscount = false), _vm.calculateOfferPrice())}},model:{value:(_vm.model.discount),callback:function ($$v) {_vm.$set(_vm.model, "discount", $$v)},expression:"model.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showValueDiscount),expression:"showValueDiscount"}],attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Value Discount","label-for":"value_discount"}},[_c('b-form-input',{attrs:{"id":"value_discount"},on:{"change":function($event){return _vm.calculateOfferPrice()}},model:{value:(_vm.model.discount_value),callback:function ($$v) {_vm.$set(_vm.model, "discount_value", $$v)},expression:"model.discount_value"}})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showValueDiscount),expression:"showValueDiscount"}],attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Price Discount","label-for":"price_discount"}},[_c('validation-provider',{attrs:{"rules":"required|between:0.1,99999","name":"Price discount "},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price_discount","state":errors.length > 0 ? false : null},model:{value:(_vm.model.price_discount),callback:function ($$v) {_vm.$set(_vm.model, "price_discount", $$v)},expression:"model.price_discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.model.is_taxes),callback:function ($$v) {_vm.$set(_vm.model, "is_taxes", $$v)},expression:"model.is_taxes"}},[_vm._v(" Is Taxes ")])],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex mb-2 mt-2"},[_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v("Detail Product Description")])]),_c('div',{staticStyle:{"height":"420px"}},[_c('quill-editor',{staticStyle:{"height":"350px"},attrs:{"options":_vm.snowOption},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)])],1),_c('div',{staticClass:"mt-2"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Create Product ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }