<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <b-form enctype="multipart/forn-data">
        <div class="d-flex mb-2 mt-2">
          <feather-icon icon="ImageIcon" size="19" />
          <h4 class="mb-0 ml-50">Image</h4>
        </div>
        <!-- Media -->
        <b-media class="mb-2">
          <template #aside>
            <b-avatar :src="image" size="90px" rounded />
          </template>
          <div class="d-flex flex-wrap">
            <b-button variant="primary" @click="$refs.refInputEl.click()">
              <input
                ref="refInputEl"
                type="file"
                @change="uploadImage"
                class="d-none"
              />
              <span class="d-none d-sm-inline">Add</span>
              <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>
          </div>
        </b-media>
        <div class="d-flex mb-2 mt-2">
          <feather-icon icon="BoxIcon" size="19" />
          <h4 class="mb-0 ml-50">Product Detail</h4>
        </div>
        <!-- User Info: Input Fields -->
        <b-row>
          <!-- Field: sku -->
          <b-col cols="12" md="4">
            <b-form-group label="Sku" label-for="sku">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="sku"
              >
                <b-form-input
                  id="sku"
                  v-model="model.sku"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: identification_number -->
          <b-col cols="12" md="4">
            <b-form-group label="Name" label-for="name">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="name"
              >
                <b-form-input
                  id="name"
                  v-model="model.name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: First  Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Price" label-for="price">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0.1,99999"
                name="Price"
              >
                <b-form-input
                  id="Price"
                  v-model="model.price"
                  :state="errors.length > 0 ? false : null"
                  @change="calculateOfferPrice()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Type Commission" label-for="user-type">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Type Commission"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeCommission"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  v-model="model.type"
                  input-id="user-type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Last  Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Comission" label-for="percentage_comission">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Comission"
              >
                <b-form-input
                  id="percentage_comission"
                  v-model="model.percentage_comission"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="user-status">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                v-model="model.status"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Discount" label-for="user-type">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Discount"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  v-model="model.discount"
                  input-id="user-type"
                  @input="
                    model.discount > 0
                      ? ((showValueDiscount = true), calculateOfferPrice())
                      : ((showValueDiscount = false), calculateOfferPrice())
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-show="showValueDiscount">
            <b-form-group label="Value Discount" label-for="value_discount">
              <b-form-input
                id="value_discount"
                v-model="model.discount_value"
                @change="calculateOfferPrice()"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-show="showValueDiscount">
            <b-form-group label="Price Discount" label-for="price_discount">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0.1,99999"
                name="Price discount "
              >
                <b-form-input
                  id="price_discount"
                  v-model="model.price_discount"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" class="mb-2">
            <b-form-checkbox
              v-model="model.is_taxes"
              name="check-button"
              switch
              inline
            >
              Is Taxes
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" md="12">
            <div class="d-flex mb-2 mt-2">
              <h4 class="mb-0 ml-50">Detail Product Description</h4>
            </div>
            <div style="height: 420px">
              <quill-editor
                style="height: 350px"
                v-model="model.description"
                :options="snowOption"
              />
            </div>
          </b-col>
        </b-row>
        <div class="mt-2">
          <b-button
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            Create Product
          </b-button>
        </div>
      </b-form>
    </b-card>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import store from "@/store";
import router from "@/router";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import ProductStoreModule from "../productStoreModule";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, ProductStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const toast = useToast();
    const validations = ref({
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    });

    const model = ref({
      is_taxes: false,
      stock: 0,
      discount: 0,
      discount_value: 0,
      price_discount: 0,
    });

    const image = ref("");

    const parser = JSON.parse(localStorage.getItem("userData"));
    const idOffice = parser.dataUser.officeEmployee_officeId;
    const idRol = parser.userData.rolId;

    const cityOptions = ref([]);
    const snowOption = snowOption;
    const simpleRules = ref(null);

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];

    const typeCommission = [
      { label: "%", value: 1 },
      { label: "Fixed", value: 2 },
    ];

    const discountOptions = [
      { label: "False", value: 0 },
      { label: "%", value: 1 },
      { label: "Fixed", value: 2 },
    ];

    const calculateOfferPrice = () => {
      if (model.value.discount == 1) {
        let descuento = (model.value.discount_value * model.value.price) / 100;
        model.value.price_discount = model.value.price - descuento;
      } else if (model.value.discount == 2) {
        model.value.price_discount =
          model.value.price - model.value.discount_value;
      } else {
        model.value.price_discount = model.value.price;
      }
    };

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
      }
    );

    const a = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { cities } = response.data.data;

        cities.map((data, item) => {
          a.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = a;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const uploadImage = (e) => {
      let file = e.target.files[0];
      if (file.size > 2097152) {
        return toast({
          component: ToastificationContent,
          props: {
            title: `ERROR`,
            icon: "AlertOctagonIcon",
            variant: "danger",
            text: `image exceeds the allowed weight 2Mb`,
            solid: true,
          },
        });
      }
      Object.assign(model.value, { file: file });
      viewImage(file);
    };

    const viewImage = (imageP) => {
      let reader = new FileReader();
      reader.onload = (e) => {
        image.value = e.target.result;
      };
      reader.readAsDataURL(imageP);
    };

    const validationForm = () => {
      simpleRules.value.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line

          let formData = new FormData();
          formData.append("description", model.value.description);
          formData.append("file", model.value.file);
          formData.append("name", model.value.name);
          formData.append("type_comission", model.value.type);
          formData.append(
            "percentage_comission",
            model.value.percentage_comission
          );
          formData.append("price", model.value.price_discount);
          formData.append("inventory_price", model.value.price);
          formData.append("sku", model.value.sku);
          formData.append("status", model.value.status);
          formData.append("stock", model.value.stock);
          formData.append("tax", model.value.is_taxes);
          formData.append("discount", model.value.discount);
          formData.append("discount_value", model.value.discount_value);
          formData.append("idOffice", idOffice);
          formData.append("idRol", idRol);
          store
            .dispatch("app-user/addProduct", formData)
            .then((response) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `SUCCESS`,
                  icon: "CheckCircleIcon",
                  text: `${response.data.msg}`,
                  variant: "success",
                },
              });
              router.push({ name: "apps-product-list" });
            })
            .catch((err) => {
              toast({
                component: ToastificationContent,
                props: {
                  title: `ERROR`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                  text: `${err}`,
                  solid: true,
                },
              });
            });
        }
      });
    };

    return {
      avatarText,
      cityOptions,
      statusOptions,
      typeCommission,
      discountOptions,
      validations,
      image,
      uploadImage,
      snowOption,
      model,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      simpleRules,
      validationForm,
      showValueDiscount: false,
      calculateOfferPrice,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
